<template>
  <v-dialog
    :id="`modal-${data.id}`"
    v-model="isVisibleDialog"
    scrollable
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title class="py-0 px-0">
        <v-row>
          <v-col cols="12">
            <v-tabs v-model="selectTab">
              <v-tab
                v-for="item in tabItems"
                :key="item"
              >
                {{ item.replace(/_/g, " ") }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="py-0 px-0">
        <v-col cols="12">
          <div
            v-if="!selectTab"
            class="rawData"
          >
            <!-- <json-viewer :value="telematicJSON"></json-viewer> -->

            <json-viewer
              :value="data"
              :expand-depth="5"
              copyable
              boxed
              sort
            />
          </div>
          <div
            v-else
            class="pusherData"
          >
            <!-- <json-viewer :value="telematicJSON"></json-viewer> -->
            <json-viewer
              :value="data.pusher_data"
              :expand-depth="5"
              copyable
              boxed
              sort
            />
          </div>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeModal()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
    <!--  -->
  </v-dialog>
</template>

<script>
import JsonViewer from "vue-json-viewer";
export default {
  components: { JsonViewer },
  props: {
    isVisibleDialog: Boolean,
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectTab: 0,
    };
  },
  computed: {
    tabItems() {
      return this.data.pusher_data ? ["raw_data", "pusher_data"] : ["raw_data"];
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
